/* Estilos existentes */

.text-center {
  text-align: center; /* Centra el texto horizontalmente */
  color: #fff; /* Color del texto */
}

.text-center h1 {
  font-size: 2.5em; /* Tamaño del título */
  margin-bottom: 20px; /* Espaciado inferior */
}

.text-center p {
  font-size: 1.2em; /* Tamaño del texto */
  margin-bottom: 40px; /* Espaciado inferior */
}

.text-center button {
  background-color: #007bff; /* Color de fondo del botón */
  color: #fff; /* Color del texto del botón */
  border: none;
  border-radius: 5px;
  padding: 15px 30px; /* Espaciado interno */
  font-size: 1em; /* Tamaño del texto del botón */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Transición suave al pasar el cursor sobre el botón */
}

.text-center button:hover {
  background-color: #0056b3; /* Cambia el color de fondo al pasar el cursor sobre el botón */
}

.img-container {
  width: 100%;
  overflow: hidden; /* Evita que la imagen se desborde del contenedor */
}

.img-container img {
  width: 100%; /* Hace que la imagen ocupe todo el ancho del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  display: block; /* Evita espacios adicionales debajo de la imagen */
}

.services-section {
  background-color: #fff;
}

.services-section h2 {
  color: #333; /* Cambia el color del texto a negro (#333) */
}

.pTextStyle {
  color: var(--gray);
}

/* globalStyles.css */
.slider-section {
  max-width: 1200px; /* Ajusta esto según el ancho deseado */
  margin: 0 auto;
}

.slider-section img {
  width: 100%;
  height: auto;
  max-height: 500px; /* Ajusta esto según la altura deseada */
  object-fit: cover;
}

/* Nuevos estilos */

.services-section {
  background-color: #f9f9f9; /* Cambié el color de fondo para mayor contraste */
  padding: 60px 0;
  border-radius: 10px;
}

.services-section h2 {
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333; /* Reitero el color para asegurar coherencia */
}

.pTextStyle {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #555; /* Aseguro que el texto tenga un color adecuado */
}

.service-card {
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-card h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #f74f39;
}

.service-card p {
  font-size: 1rem;
  color: #555;
}

@media (max-width: 768px) {
  .pTextStyle {
    font-size: 1rem;
  }

  .service-card h4 {
    font-size: 1.2rem;
  }

  .service-card p {
    font-size: 0.9rem;
  }
}
