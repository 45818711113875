/* Estilos generales */
body {
    
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.productos-section {
    text-align: center;
    
    background-color: #171717;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
@media only screen and (min-width: 600px) {
    .productos-section {
        margin: 40px;
    }
}

.productos-section h1 {
    font-size: 2.5em;
    color: #f74f39; /* Cambiado a color de marca */
    margin-bottom: 20px;
}

/* Estilos de las tarjetas de precios */
.pricing-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 30px 20px;
    width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
}

.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card h2 {
    font-size: 1.8em;
    color: #f74f39; /* Cambiado a color de marca */
    margin-bottom: 20px;
}

.card .price {
    font-size: 2em;
    color: #f74f39; /* Cambiado a color de marca */
    margin: 20px 0;
    font-weight: bold;
}

.card .price span {
    font-size: 0.5em;
    color: #666;
}

.card ul {
    list-style-type: none;
    padding: 0;
    color: #555;
    font-size: 1em;
    margin: 20px 0;
}

.card ul li {
    padding: 10px 0;
    border-bottom: 1px solid #52505045;
}

.card ul li:last-child {
    border-bottom: none;
}

.card button {
    display: block;
    width: 100%;
    background-color: #f74f39;; /* Cambiado a color de marca */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 15px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-weight: bold;
}

.card button:hover {
    background-color: #ded3d3; /* Un tono más oscuro para el hover */
}
