.contactoZone {
    background-color: #f9f9f9;
    padding: 60px 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .contact-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-family: 'Helvetica Neue', sans-serif;
  }
  
  .contact-description {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #555;
  }
  
  .contact-form .form-group {
    margin-bottom: 20px;
  }
  
  .contact-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .contact-form .form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form .btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form .btn:hover {
    background-color: #0056b3;
  }
  
  .contact-info {
    text-align: left;
    padding-left: 20px;
  }
  
  .contact-info h4 {
    margin-bottom: 20px;
    font-family: 'Helvetica Neue', sans-serif;
  }
  
  .contact-info p {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .contact-info .contact-logo {
    max-width: 150px;
    margin-top: 20px;
  }
  