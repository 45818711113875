.footer {
  background-color: #333;
  color: #fff;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex: 1;
}

h2,
h3 {
  color: #fff;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  color: #fff;
  text-decoration: none;
}

.bottom-bar {
  background-color: #222;
  padding: 10px 0;
  text-align: center;
}

/* Definir la animación */
@keyframes oscilacion {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Aplicar la animación a la imagen */
.img-oscilante {
  animation: oscilacion 2s ease infinite; /* Nombre de la animación, duración, función de temporización y bucle infinito */
}


.bg-dark {
  background-color: #343a40;
}

.text-white {
  color: #fff;
}

.p-5 {
  padding: 3rem;
}

.footer-logo {
  max-width: 150px;
}

.footer-logo,
.img-oscilante {
  display: block;
  margin: 0 auto;
}

.mt-3 {
  margin-top: 1rem;
}

.list-unstyled {
  list-style: none;
  padding: 0;
}

.list-unstyled li {
  margin-bottom: 0.5rem;
}

.list-unstyled a {
  color: #fff;
  text-decoration: none;
}

.list-unstyled a:hover {
  text-decoration: underline;
}

.pixeloOnlyFont {
  font-family: 'Helvetica Neue', sans-serif;
  font-weight: bold;
}

.brandOnFooter {
  color: #f74f39;
}

.text-center {
  text-align: center;
}

.mb-0 {
  margin-bottom: 0;
}
