/* pixeloPreviewPage.css */

/* Estilos generales */
.pixelo-spa {
    font-family: Arial, sans-serif;
  }
  
  /* Encabezado */
  .header {
    background-color: #333;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .logo img {
    width: 100px;
    height: auto;
  }
  
  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navbar ul li {
    margin-right: 20px;
    cursor: pointer;
  }
  
  .navbar ul li:hover {
    text-decoration: underline;
  }
  
  /* Contenido principal */
  .main-content {
    padding: 20px;
  }
  
  /* Pie de página */
  .footer {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-align: center;
  }
  

  .pixeloNavBarIcon{
    height: 80px;
  }
  .button-link {
    background: none;
    border: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    text-decoration: none;
    padding: 0;
  }
  
  .nav-item .nav-link {
    padding: 0.5rem 1rem;
    display: block;
  }
  
  .nav-item.active .nav-link {
    color: orange;
    font-weight: bold;
  }
  
  .nav-item .nav-link:focus {
    outline: none;
  }
  