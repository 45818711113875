/* PIXELO PANTONE  */

.pixeloOrange {
  color: #f74f39;
}

.pixeloGrey {
  color: #171717;
}

.pixeloRed {
  color: #e63946;
}

.pixeloBlue {
  color: #1d3557;
}

.pixeloLightBlue {
  color: #a8dadc;
}

.pixeloGreen {
  color: #2a9d8f;
}

.pixeloYellow {
  color: #e9c46a;
}

.pixeloDarkYellow {
  color: #f4a261;
}

.pixeloPurple {
  color: #6a0572;
}

.pixeloLightPurple {
  color: #9c89b8;
}

.pixeloLightGrey {
  color: #d3d3d3;
}

.pixeloDarkGrey {
  color: #2d2d2d;
}

/* HomePage.css */
.homepage-container {
  padding-top: 20px;
}

@font-face {
  font-family: "pixeloFont";
  src: url("../assets/fonts/MODERNA_.TTF") format("woff2"),
  
}

@font-face {
  font-family: "secondaryFont";
  src: url("../assets/fonts/coolvetica\ rg.otf") format("woff2"),
  
}

.pixeloFont{
  font-family: 'pixeloFont';
  color: #f74f39;
}

.brandOnFooter{
  font-family: 'pixeloFont';
  color: #f74f39;
}

.bePixeloEmailSection1{
  color: #e0ccca;

}
.bePixeloEmail{
  color: #f74f39;
}


.pixeloOnlyFont{
  font-family: 'pixeloFont';
}
.secondaryFont{
  font-family: 'secondaryFont';
}

/* Define la animación */
@keyframes fadeIn {
  from {
    opacity: 0; /* La imagen está completamente transparente al principio */
  }
  to {
    opacity: 1; /* La imagen se hace completamente visible al final */
  }
}

/* Aplica la animación a la clase de la imagen */
.pixeloNavBarIcon {
  animation: fadeIn 1s ease-in; /* Nombre de la animación, duración y función de temporización */
}

/* Define la animación de floating */
@keyframes floating {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-2px, -2px); /* Movimiento hacia arriba y hacia la izquierda */
  }
  100% {
    transform: translate(2px, 2px); /* Movimiento hacia abajo y hacia la derecha */
  }
}

/* Aplica la animación de floating a la imagen */
.pixeloNavBarIcon {
  animation: fadeIn 1s ease-in, floating 3s ease-in-out infinite alternate; /* Combina la animación de fadeIn y la animación de floating */
}


/* globalStyles.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
